<template>
  <v-container>
    <v-row v-if="grade" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên lớp học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input :text="grade.name" v-model="grade.name"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="status" rules="required" v-slot="{ errors }">
          <v-switch v-model="grade.status"></v-switch>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>

      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(grade.order)"
            v-model="grade.order"
        ></hnr-input>
      </v-col>
    </v-row>
    <!--    <v-row class="align-center justify-center">-->
    <!--      <v-col cols="2" class="pa-1">-->
    <!--        <span> Tên cuối danh mục </span>-->
    <!--      </v-col>-->
    <!--      <v-col cols="10" class="pa-1">-->
    <!--        <hnr-input-->
    <!--          :text="grade.short_content"-->
    <!--          v-model="grade.short_content"-->
    <!--        ></hnr-input>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Môn học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            multiple
            v-model="grade.subjectId"
            :items="subjects"
            item-text="name"
            item-value="id"
            label="Chọn môn học"
            dense
            outlined></v-select>
      </v-col>

    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Banner ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            class="pl-1"
            prepend-icon="mdi-camera"
            v-model="image"
            label="Tải ảnh"
            @change="onChangeFileUpload"
            @click:clear="clearImage"
        ></v-file-input>
        <div id="preview">
          <v-img :src="replace_image"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import {SAVE_IMAGE} from "@/store/image.module";

export default {
  data() {
    return {
      image: {},
    };
  },
  props: {
    grade: Object,
    subjects: Array
  },
  components: {
    HnrInput,
  },
  computed: {
    replace_image() {
      if (this.grade) {
        return this.grade.img_banner;
      }
      return "";
    },
  },
  methods: {
    convertNumberToString(number) {
      if (number !== undefined) {
        return number.toString();
      }
      return "0";
    },
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data.status == 1 && data.url) {
          this.grade.img_banner = data.url;
        }
      });
    },
    clearImage() {
      this.grade.img_banner = "";
    },
  },
};
</script>

<style lang="scss">
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: auto;

  img {
    max-width: 300px;
    max-height: 500px;
  }
}
</style>
